<template>
  <section>
    <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4">

      <div class="d-flex justify-content-between" @click="openFileBottomSheet">
        <span class="my-1">{{ $t('Change image') }}</span>
        <v-btn color="primary" class="" rounded>{{ $t('Change') }}</v-btn>
      </div>

      <div id="previewContainer" class="imagePreviewWrapper">

        <v-img :lazy-src="require('@/assets/images/placeholder.png')" :src="previewImage" class="img-preview" />

      </div>

      <span class="my-1 d-block">{{ $t('Title') }}</span>
      <v-text-field outlined single-line v-model="post.title" type="text" :rules="titleRules" required counter="100" />

      <span class="my-1 d-block">{{ $t('Category') }}</span>
      <v-select :label="$t('Choose')" v-model="post.groups" outlined required single-line small-chips
        :loading="categoryLoading" multiple return-object :no-data-text="$t('No data')" :items="categoryList"
        item-text="value" item-value="id" :rules="[(v) => !!v]">
      </v-select>

      <span class="my-1 d-block">{{ $t('Description') }}</span>
      <v-textarea outlined v-model="post.description" type="text" counter="1000" :rules="descriptionRules" required />

      <div class="mt-1 text-center">
        <v-btn color="primary" large class="col-12" @click="validate()" :loading="submit_loading"
          :disabled="submit_loading">
          {{ $t('Submit') }}
        </v-btn>
      </div>
    </v-form>

    <vue-bottom-sheet ref="fileBottomSheet">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <v-list class="w-100 pt-0 mt-auto" light shaped>
          <span class="text-center mb-1 d-block f14 font-weight-bold">{{ $t('File selection') }}</span>
          <input ref="fileInput" type="file" @input="pickFile" accept="image/*" hidden>
          <v-list-item ripple @click="selectImage">
            <v-list-item-icon class="my-0 align-self-center">
              <v-sheet color="#fff3e1" elevation="0"
                class="d-flex align-items-center justify-content-center rounded-lg p-2">
                <iconly type="bulk" name="image" color="#f8b758" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-title tag="span" class="f14">
              <span v-if="isMobile()">{{ $t('Select image from gallery') }}</span>
              <span v-else>{{ $t('Select image') }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-btn block color="primary" class="my-2" rounded>{{ $t('Close') }}
          </v-btn>
        </v-list>
      </section>
    </vue-bottom-sheet>

  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

import * as blobUtil from 'blob-util'

export default {
  data() {
    return {
      post: {},

      submit_loading: false,
      categoryLoading: true,
      valid: false,

      categoryList: [],

      previewImage: null,
      
      imageName: null,
      imageExtension: null,
      
      fileChanged: false,

      titleRules: [
        v => !!v,
        v => (v && v.length <= 100) || this.$t("The maximum length is 100 characters")
      ],

      descriptionRules: [
        v => !!v,
        v => (v && v.length <= 1000) || this.$t("The maximum length is 1000 characters")
      ],

    }
  },
  methods: {
    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {

        const lastDot = file[0].name.lastIndexOf('.');
        this.imageName = file[0].name.substring(0, lastDot);
        this.imageExtension = file[0].name.substring(lastDot + 1);

        // alert(this.imageExtension)

        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
          this.fileChanged = true
        }
        reader.readAsDataURL(file[0])

        this.closeExamBottomSheet()
        this.$emit('input', file[0])
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    openFileBottomSheet() {
      this.$refs.fileBottomSheet.open();
    },
    closeExamBottomSheet() {
      this.$refs.fileBottomSheet.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    getAllCategories() {
      apiService.getActivities()
        .then((response) => {
          this.categoryList = response.data;
          this.categoryLoading = false;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    selectImage() {
      this.$refs.fileInput.click()
    },
    submitForm() {
      if (!this.post.picture) {
        this.$swal({
          icon: "warning",
          text: this.$t("No image selected. Please select an image"),
          confirmButtonText: this.$t('OK'),
        });

        return;
      }
      this.submit_loading = true;

      if (this.fileChanged) {
        this.uploadImage();
      }
      else {
        this.updateContent();
      }

    },
    uploadImage() {
      var blob = blobUtil.dataURLToBlob(this.previewImage)
      apiService
        .upload("POST", blob, this.imageName+"."+this.imageExtension, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
        .then((response) => {
          this.post.picture = response.data.originalFileDownloadUrl;
          this.post.thumbnailUrl = response.data.thumbnailPicDownloadUrl;

          this.updateContent();
        })
        .catch(() => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: this.$t('Could not upload the file!'),
            confirmButtonText: this.$t("OK"),
          });
        });
    },
    updateContent() {
        apiService
          .updatePost(this.post)
          .then(() => {
            this.submit_loading = false;
            this.$swal({
              icon: "success",
              text: this.$t('Your content was edit successfully'),
              confirmButtonText: this.$t('OK'),
            })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$router.go(-1);
                }
              });
          })
          .catch((err) => {
            this.submit_loading = false;
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
    },
    getPostById() {
      apiService.getPostById(this.$route.params.id)
        .then((response) => {
          this.post = response.data;
          this.previewImage = this.post.picture
        })
        .catch(() => {
          this.$router.go(-1);
        });
    },
    
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  created() {
    this.getAllCategories();
    this.getPostById();
  }
}
</script>
<style scoped lang="scss">
.imagePreviewWrapper {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.img-preview {
  width: 300px;
  height: 300px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
</style>